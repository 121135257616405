import { FC } from 'react';

export const StoppingBadge: FC = () => {
    return (
        <div>
            Event is stopping. Please wait. It might take a while. Event state
            will be automatically updated...
        </div>
    );
};
