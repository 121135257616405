import { FC } from 'react';

export const StartingBadge: FC = () => {
    return (
        <div>
            Event is starting. Please wait. It might take a while. Event state
            will be automatically updated...
        </div>
    );
};
