import styled from 'styled-components';

export const EventIsOfflineComponent = styled.div`
    width: '100%';
    background: black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 50px 0;
    font-size: 36px;
    color: #fff;
`;
