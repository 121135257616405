import { FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { EventDto } from '../dto/event';
import { VideoEventsService } from '../services/VideoEventsService';
import { EventViewComponent } from '../Components/EventViewComponent';
import { EventIsOfflineComponent } from './PlayerPage/EventIsOfflineComponent';
import { PlayerComponent } from './PlayerPage/PlayerComponent';

export const PlayerPage: FC = () => {
    const [loading, setLoading] = useState(true);
    const [event, setEvent] = useState<EventDto | null>(null);
    const { eventId } = useParams();

    const findEvent = async (id: string) => {
        setLoading(true);
        try {
            setEvent(await new VideoEventsService().findById(id));
        } catch (e) {}
        setLoading(false);
    };

    useEffect(() => {
        findEvent(`${eventId}`);
    }, [eventId]);

    return (
        <>
            {loading && (
                <div style={{ padding: 20, color: '#fff', background: '#000' }}>
                    Loading ...
                </div>
            )}
            {!loading && event && (
                <div>
                    {event.status === 'RUNNING' && (
                        <PlayerComponent event={event} />
                    )}
                    {event.status !== 'RUNNING' && (
                        <EventIsOfflineComponent>
                            <div>
                                <div>Event is offline</div>
                                <div>
                                    <Link to="/">Back to event page</Link>
                                </div>
                            </div>
                        </EventIsOfflineComponent>
                    )}
                    <EventViewComponent event={event} showState={false} />
                    {event.status === 'RUNNING' && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 20,
                            }}
                        >
                            <a
                                href={`https://k6o7c5q1w7.execute-api.eu-west-1.amazonaws.com/demo/player/${event.id}`}
                                target="_blank"
                            >
                                Open player{' '}
                                <img src="/new_window_icon.png" width={14} />
                            </a>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
