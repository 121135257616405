import { EventDto } from '../dto/event';
import { EventExtractor } from '../extractors/event';

export class VideoEventsService {
    private async action(action: 'start' | 'stop' | 'state', event: EventDto) {
        const result = await fetch(
            `https://k6o7c5q1w7.execute-api.eu-west-1.amazonaws.com/demo/events/${event.id}`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ action }),
            }
        );
        return await result.json();
    }

    async start(event: EventDto) {
        const response = await this.action('start', event);
        return `${response.state}`;
    }

    async stop(event: EventDto) {
        const response = await this.action('stop', event);
        return `${response.state}`;
    }

    async delete(event: EventDto) {
        const result = await fetch(
            `https://k6o7c5q1w7.execute-api.eu-west-1.amazonaws.com/demo/events/${event.id}`,
            {
                method: 'DELETE',
            }
        );
        console.log('Deleted?', await result.json());
    }

    async find(): Promise<EventDto[]> {
        const response = await fetch(
            'https://k6o7c5q1w7.execute-api.eu-west-1.amazonaws.com/demo/events'
        );
        const jsonData = await response.json();
        return jsonData['events'].map((eventData: any) =>
            EventExtractor.fromSingleEventResponse(eventData)
        );
    }

    async findById(id: string): Promise<EventDto> {
        const result = await fetch(
            `https://k6o7c5q1w7.execute-api.eu-west-1.amazonaws.com/demo/events/${id}`
        );
        const data = await result.json();
        return EventExtractor.fromSingleEventResponse(data['event']);
    }

    async state(event: EventDto): Promise<string> {
        const response = await this.action('state', event);
        return `${response.state}`;
    }

    async create(event: EventDto): Promise<string> {
        const result = await fetch(
            `https://k6o7c5q1w7.execute-api.eu-west-1.amazonaws.com/demo/events/`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    title: event.title,
                    description: event.description,
                    start: event.start,
                    end: event.end,
                }),
            }
        );
        const data = await result.json();
        console.log('Created', data);
        return data['id'];
    }
}
