import { FC } from 'react';
import styled from 'styled-components';
import { EventDto } from '../dto/event';
import { EventStateBadge } from './EventStateBadge';

const VideoTitle = styled.h1`
    padding: 10px 10px 10px 10px;
    margin: 0;
`;

const VideoDescription = styled.h3`
    padding: 0 10px 20px 10px;
    margin: 0;
`;

interface IProps {
    event: EventDto;
    showState: boolean;
}

export const EventViewComponent: FC<IProps> = ({
    event,
    showState: showStatus,
}) => {
    return (
        <>
            {showStatus && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <div style={{ paddingLeft: 10 }}>
                            <EventStateBadge event={event} />
                        </div>
                    </div>
                    <VideoTitle style={{ flex: 1 }}>{event.title}</VideoTitle>
                </div>
            )}
            {!showStatus && (
                <VideoTitle style={{ flex: 1 }}>{event.title}</VideoTitle>
            )}

            <VideoDescription>{event.description}</VideoDescription>
        </>
    );
};
