import { FC, useState } from 'react';
import styled from 'styled-components';
import { VideoEventsService } from '../../services/VideoEventsService';

const FieldSetContainer = styled.fieldset`
    padding-bottom: 20;
    border: 0;
    text-align: center;
    label {
        display: block;
        text-align: left;
    }
    input,
    textarea {
        width: 100%;
        padding: 10px;
    }
    textarea {
        height: 150px;
    }
    button {
        background: #1e1e1e;
        color: #fff;
        padding: 10px 20px;
    }
    input:focus,
    textarea:focus {
        outline: none;
        border-color: black;
    }
`;

interface IProps {
    onCreated: CallableFunction;
}

export const CreateEventForm: FC<IProps> = ({ onCreated }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [saving, setSaving] = useState(false);

    const onSubmit = async (event: any) => {
        const service = await new VideoEventsService();
        const eventToSave = {
            id: '',
            title,
            description,
            start: -1,
            end: -1,
            playback: '',
            status: '',
        };
        event.preventDefault();
        setSaving(true);
        console.log('Submitting ...', { title, description });
        const id = await service.create(eventToSave);
        console.log('Submitted ...', { title, description });
        onCreated({ ...eventToSave, id });
        // setSaving(false);
    };

    return (
        <div style={{}}>
            <h1>Create Streaming Event</h1>
            {saving && (
                <div>
                    Creating <span style={{ fontWeight: 'bold' }}>{title}</span>{' '}
                    event. Please wait ....
                </div>
            )}
            {!saving && (
                <form action="" onSubmit={onSubmit}>
                    <FieldSetContainer>
                        <label htmlFor="title">Title</label>
                        <input
                            id="title"
                            type="text"
                            placeholder="Event title"
                            value={title}
                            autoComplete="off"
                            onChange={(event: any) => {
                                setTitle(event.target.value);
                            }}
                        />
                    </FieldSetContainer>
                    <FieldSetContainer>
                        <label htmlFor="title">Description</label>
                        <textarea
                            id="title"
                            placeholder="Event description"
                            value={description}
                            autoComplete="off"
                            onChange={(event: any) => {
                                setDescription(event.target.value);
                            }}
                        />
                    </FieldSetContainer>
                    <FieldSetContainer>
                        <div>Starts and ends manually after creation</div>
                    </FieldSetContainer>

                    <FieldSetContainer>
                        <button type="submit">Create</button>
                    </FieldSetContainer>
                </form>
            )}
        </div>
    );
};
