import { createBrowserRouter } from 'react-router-dom';
import { Layout } from './Layout';
import { HomePage } from './Pages/HomePage';
import { PlayerPage } from './Pages/PlayerPage';

export const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <Layout>
                <HomePage />
            </Layout>
        ),
        errorElement: <div>404?</div>,
    },
    {
        path: '/play/:eventId',
        element: <PlayerPage />,
    },
]);
