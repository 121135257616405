import { FC, useEffect, useState } from 'react';
import { EventDto } from '../dto/event';
import { VideoEventsService } from '../services/VideoEventsService';
import { CreateEventForm } from './HomePage/CreateEventForm';
import { ViewEventComponent } from './HomePage/ViewEventComponent';

export const HomePage: FC = () => {
    const [loading, setLoading] = useState(true);
    const [event, setEvent] = useState<EventDto | null>(null);
    const refreshEvents = async () => {
        try {
            const events = await new VideoEventsService().find();
            setEvent(events.length > 0 ? events[0] : null);
        } catch (e) {
            console.error('Failed to load events', e);
        }
        setLoading(false);
    };

    useEffect(() => {
        refreshEvents();
    }, []);

    const onEventStateChange = (newState: string) => {
        if (event) {
            setEvent({ ...event, status: newState });
        }
    };

    return (
        <div>
            {loading && <div>Loading ...</div>}
            {!loading && event === null && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                    }}
                >
                    <div style={{ width: 600 }}>
                        <CreateEventForm
                            onCreated={async (event: EventDto) => {
                                await new VideoEventsService().state(event);
                                await refreshEvents();
                            }}
                        />
                    </div>
                </div>
            )}
            {!loading && event !== null && (
                <ViewEventComponent
                    event={event}
                    onEventStateChange={onEventStateChange}
                    onEventDeleted={refreshEvents}
                />
            )}
        </div>
    );
};
