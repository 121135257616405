import { Children, FC, ReactNode } from 'react';
import { Header } from './Layout/Header';

interface IProps {
    children: ReactNode;
}

export const Layout: FC<IProps> = ({ children }) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                minHeight: '100vh',
                flexDirection: 'row',
                fontFamily: "'Roboto', sans-serif",
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <Header />
                {children}
            </div>
        </div>
    );
};
