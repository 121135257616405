import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { EventStateBadge } from '../../../Components/EventStateBadge';
import { EventDto } from '../../../dto/event';
import { VideoEventsService } from '../../../services/VideoEventsService';
import { StoppingBadge } from './StoppingBadge';

interface IProps {
    event: EventDto;
    onEventStateChange: CallableFunction;
}

export const RunningEventComponent: FC<IProps> = ({
    event,
    onEventStateChange,
}) => {
    const [stopping, setStopping] = useState(false);

    const stopEvent = async () => {
        setStopping(true);
        try {
            if (event.status !== 'RUNNING') {
                console.error(
                    'Event cannot be stopped because channel state is not RUNNING. State is -> ',
                    event.status
                );
                return;
            }
            onEventStateChange(await new VideoEventsService().stop(event));
        } catch (e) {}
        setStopping(false);
    };

    return (
        <>
            {!stopping && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 20,
                    }}
                >
                    <Link to={`/play/${event.id}`}>Watch</Link>
                    <button onClick={stopEvent} className="redLink">
                        Stop
                    </button>
                </div>
            )}
            {stopping && <StoppingBadge />}
        </>
    );
};
