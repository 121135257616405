import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const HeaderContainer = styled.div`
    border-bottom: 1px solid #cdcdcd;
    padding: 10px 10px 10px 10px;
    margin-bottom: 10px;
    text-align: left;
    display: block;

    .delimiter {
        margin: 0 10px;
    }
`;

export const Header: FC = () => {
    return <HeaderContainer>Streaming Events</HeaderContainer>;
};
