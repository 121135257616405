import { FC, useEffect, useState } from 'react';
import { EventDto } from '../../../dto/event';
import useInterval from '../../../Hooks/useInterval';
import { VideoEventsService } from '../../../services/VideoEventsService';
import { StartingBadge } from './StartingBadge';
import { StoppingBadge } from './StoppingBadge';

interface IProps {
    event: EventDto;
    onEventStateChange: CallableFunction;
}

export const InProgressEventComponent: FC<IProps> = ({
    event,
    onEventStateChange,
}) => {
    const [, setCheckingStatus] = useState(false);

    const refreshState = async () => {
        setCheckingStatus(true);
        try {
            const newState = await new VideoEventsService().state(event);
            if (newState !== event.status) {
                onEventStateChange(newState);
            }
        } catch (e) {}
        setCheckingStatus(false);
    };

    useEffect(() => {
        refreshState();
    }, []);

    useInterval(() => {
        console.log('Checking state with timeout ....');
        refreshState();
    }, 10000);

    return (
        <>
            <div>
                {event.status === 'STARTING' && <StartingBadge />}
                {event.status === 'STOPPING' && <StoppingBadge />}
            </div>
        </>
    );
};
