import { FC } from 'react';
import styled from 'styled-components';
import { EventDto } from '../../dto/event';

interface IProps {
    event: EventDto;
}

const PlayerContainer = styled.div`
    width: '100%';
    background: black;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const PlayerComponent: FC<IProps> = ({ event }) => {
    const { innerWidth: width } = window;
    return (
        <PlayerContainer>
            <iframe
                width="100%"
                height={Math.ceil((width * 9) / 16 + 20)}
                src={`https://k6o7c5q1w7.execute-api.eu-west-1.amazonaws.com/demo/player/${event.id}`}
                frameBorder="0"
            ></iframe>
        </PlayerContainer>
    );
};
