import { FC, useState } from 'react';
import { EventDto } from '../../dto/event';
import { EventStateBadge } from '../../Components/EventStateBadge';
import { VideoEventsService } from '../../services/VideoEventsService';
import { Link } from 'react-router-dom';
import { IdleEventComponent } from './ViewEventComponent/IdleEventComponent';
import { EventViewComponent } from '../../Components/EventViewComponent';
import { RunningEventComponent } from './ViewEventComponent/RunningEventComponent';
import { InProgressEventComponent } from './ViewEventComponent/InProgressEventComponent';

interface IProps {
    event: EventDto;
    onEventStateChange: CallableFunction;
    onEventDeleted: CallableFunction;
}

export const ViewEventComponent: FC<IProps> = ({
    event,
    onEventStateChange,
    onEventDeleted,
}) => {
    return (
        <>
            <EventViewComponent event={event} showState />
            <div style={{ padding: '0 10px' }}>
                {event.status !== 'RUNNING' && event.status !== 'IDLE' && (
                    <InProgressEventComponent
                        event={event}
                        onEventStateChange={onEventStateChange}
                    />
                )}

                {event.status === 'IDLE' && (
                    <IdleEventComponent
                        event={event}
                        onEventDeleted={onEventDeleted}
                        onEventStateChange={onEventStateChange}
                    />
                )}

                {event.status === 'RUNNING' && (
                    <RunningEventComponent
                        event={event}
                        onEventStateChange={onEventStateChange}
                    />
                )}
            </div>
        </>
    );
};
