import { EventDto } from '../dto/event';

export class EventExtractor {
    public static fromSingleEventResponse(response: any): EventDto {
        return {
            id: response['id'],
            title: response['info']['title'],
            description: response['info']['description'],
            playback: response['playback']['live']['origin'],
            status: response['channel']['status'],
            start: parseInt(response['start_ts'], 10),
            end: parseInt(response['end_ts'], 10),
        };
    }
}
