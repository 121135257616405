import { FC, useState } from 'react';
import { EventStateBadge } from '../../../Components/EventStateBadge';
import { EventDto } from '../../../dto/event';
import { VideoEventsService } from '../../../services/VideoEventsService';
import { StartingBadge } from './StartingBadge';

interface IProps {
    event: EventDto;
    onEventStateChange: CallableFunction;
    onEventDeleted: CallableFunction;
}

export const IdleEventComponent: FC<IProps> = ({
    event,
    onEventDeleted,
    onEventStateChange,
}) => {
    const [deleting, setDeleting] = useState(false);
    const [starting, setStarting] = useState(false);

    const startEvent = async () => {
        setStarting(true);
        try {
            if (event.status !== 'IDLE') {
                console.error(
                    'Event cannot be started because channel state is not IDLE. State is -> ',
                    event.status
                );
                return;
            }
            onEventStateChange(await new VideoEventsService().start(event));
        } catch (e) {}
        setStarting(false);
    };

    const deleteEvent = async () => {
        setDeleting(true);
        try {
            await new VideoEventsService().delete(event);
            onEventDeleted();
        } catch (e) {
            console.error('Failed to delete event', e);
        }
        setDeleting(false);
    };

    return (
        <>
            {!deleting && (
                <div>
                    {!starting && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 20,
                            }}
                        >
                            <button onClick={startEvent} className="blueLink">
                                Start
                            </button>
                            <button onClick={deleteEvent} className="redLink">
                                Delete
                            </button>
                        </div>
                    )}
                    {starting && <StartingBadge />}
                </div>
            )}
            {deleting && (
                <>
                    Deleting
                    <span style={{ fontWeight: 'bold' }}>{event.title}</span>.
                    Please wait
                </>
            )}
        </>
    );
};
