import { FC } from 'react';
import styled from 'styled-components';
import { EventDto } from '../dto/event';

interface IProps {
    event: EventDto;
}

const Badge = styled.div`
    display: inline-block;
    padding: 5px;
    border-radius: 5px;
    font-size: 16px;
`;

const LiveBadge = styled(Badge)`
    background-color: #db3832;
    color: #fff;
`;

const OfflineBadge = styled(Badge)`
    background-color: #757575;
    color: #fff;
`;

export const EventStateBadge: FC<IProps> = ({ event }) => {
    if (event.status === 'RUNNING') {
        return <LiveBadge>Live</LiveBadge>;
    }

    if (event.status === 'IDLE') {
        return <OfflineBadge>Offline</OfflineBadge>;
    }

    return <OfflineBadge>{event.status}</OfflineBadge>;
};
